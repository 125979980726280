import React from 'react'
// css
import '../../../App.css'
// react-scroll
import { Link} from "react-scroll";
// photos
import arrowTop from '../../../assets/next_white.svg';


const MoveTop = () => {
    return (
        <div style={{zIndex: '100'}}>
            <Link id='moveTopBtn'
            activeClass="active"
            className="moveTop-hide"
            to="product"
            spy={true}
            smooth={true}
            duration={500}>
                <img src={arrowTop} alt='moveTop' width="12"/>
            </Link>
        </div>
    )
}
export default MoveTop;