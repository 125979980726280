import React from 'react'
import { Container } from 'react-bootstrap';
import "./Legal.css"

const Legal = () => {
    return (
        <div className="legalContainer">
            <Container>
                <section id="legal">
                    <h3  className="legalTitle">Юридические документы</h3>
                    <a href="http://mobi.brt.tj/legal/license-agreement.html" target="_blank" rel="noopener noreferrer">Лицензионное соглашение на использование программы «BRT Online» для мобильных устройств</a>
                    <a href="http://mobi.brt.tj/legal/privacy-policy.html" target="_blank" rel="noopener noreferrer">Политика конфиденциальности мобильного приложения «BRT Online»</a>
                    <a href="http://mobi.brt.tj/legal/public-offer.html" target="_blank" rel="noopener noreferrer">Публичная оферта об оказании услуг по осуществлению платежей с использованием банковских карт через кошелек «BRT Online»</a>
                </section>
            </Container>
        </div>
    )
}
export default Legal;
