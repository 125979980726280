import React from 'react'
// css
import './AdvantageCol.css'
// react-boostrap
import { Col } from 'react-bootstrap'

const AdvantageCol = ({caption, text,imgSrc}) => {
    return (
        <Col className='col-12 col-md-4 col-lg-4 d-flex align-items-center flex-column'>
            <img src={imgSrc} alt={caption}/>
            <span className='colCaption'>{caption}</span>
            <p className='colText'>{text}</p>
        </Col>
    )
}
export default AdvantageCol;