import React from "react";
// css
import "./App.css";
// components
import Header from "./components/Header/Header";
import Footer from "./components/Header/Footer/Footer";
import MoveTop from "./components/utils/MoveTop/MoveTop";
import Home from "./components/Home/Home";
import Notfound from "./components/Notfound/Notfound";
// react-router
import { Route, Switch } from "react-router";

const App = () => {
  
  let scrollHeader = window.pageYOffset
  document.addEventListener('scroll', (e) => {
      if (window.pageYOffset > 300) {
          if (scrollHeader <= window.pageYOffset) {
              document
                  .getElementById('moveTopBtn')
                  .classList.add('moveTop-hide')
              document
                  .getElementById('moveTopBtn')
                  .classList.remove('moveTop')
              scrollHeader = window.pageYOffset
          } else if (scrollHeader > window.pageYOffset) {
              document
                  .getElementById('moveTopBtn')
                  .classList.remove('moveTop-hide')
              document
                  .getElementById('moveTopBtn')
                  .classList.add('moveTop')
              scrollHeader = window.pageYOffset
          }
      } else {
          document
              .getElementById('moveTopBtn')
              .classList.add('moveTop-hide')
          document
              .getElementById('moveTopBtn')
              .classList.remove('moveTop')
      }
  })

  return (
    <>
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <main>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="*">
            <Notfound />
          </Route>
        </Switch>
        <MoveTop />
      </main>
      <footer className="footer mt-auto" id="footerCont">
        <Footer />
      </footer>
    </div>
    </>
  );
};

export default App;
