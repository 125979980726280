import React from "react";
// components
import Advantage from "../Advantage/Advantage";
import DownloadProduct from "../DownloadProduct/DownloadProduct";
import Questions from "../Questions/Questions";
import Product from "../Product/Product";
import Legal from "../Legal/Legal";

const Home = () => {
  return (
    <>
      <Product />
      <Advantage />
      <DownloadProduct />
      <Questions />
      <Legal />
    </>
  );
};

export default Home;
