import React from "react";
// css
import "./Notfound.css";
// react-bootstrap
import { Container } from "react-bootstrap";

const Notfound = () => {
  return (
    <Container className="notfound-container" fluid>
      <h1 className="notfound-title">Страница 404</h1>
      <Container>
        <div className="notfound-text">По вашему запросу ничего не найдено</div>
      </Container>
    </Container>
  );
};

export default Notfound;
