import React from 'react'
import Accordion from './Accordion/Accordion'
// css
import './Questions.css'
// react-bootstrap
import { Container } from 'react-bootstrap'
// react-scroll
import { Link } from 'react-scroll'

const Questions = () => {
    return (
        <>
            <Container fluid className='questionsContainer' id="questionsContainer">
                <Container className='d-flex justify-content-center flex-column align-items-center'>
                    <h2 className='questionsCaption'>Вопросы и ответы</h2>
                    <Accordion question="Что такое БРТ Онлайн?" answer="БРТ Онлайн - это быстрый и безопасный доступ к вашим счетам и картам."/>
                    <Accordion question="Могу ли я следить за своими депозитами и кредитами в приложении БРТ Онлайн?" answer="Вы можете следить за своими кредитами и депозитами в разделе Мой банк."/>
                    {/* <Accordion question="Что нужно для перехода из старой версии БРТ Онлайн?" answer="Для перехода на новую версию вам нужен ваш ИНН код. Вы можете найти этот код на своем паспорте."/> */}
                    <Accordion question="Как  мне оплатить счет за коммунальные услуги, пополнить баланс телефона и т.д в приложении БРТ Онлайн?" answer="Зайдите на раздел Платежи и оплачивайте мобильную связь, интернет, коммунальные услуги, и многое другое без комиссии."/>
                    <Link
                        activeClass="active"
                        to="download"
                        spy={true}
                        smooth={true}
                        duration={500}>
                        <button className='questionsBtn' id="questionsBtn">Скачать приложение</button>
                    </Link>
                </Container>
            </Container>
        </>
    )
}
export default Questions;