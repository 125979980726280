import React, { createContext, useEffect, useState } from 'react'

export const StoreContext = createContext()

export const StoreProvider  = (props) => {

    const initialState = () => ({
        categoryName : 1,
        cashbackList: [],
        listLength: 0,
        loading: true,
    })

    const [store, setStore] = useState(initialState)

    const getCashback = async () => {
        setStore({...store, loading: true});
        try {
          const apiConfig = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          };
    
          const response = await fetch("https://online.humo.tj/api/lk/v1/cashback_categories", apiConfig);
          const responseJson = await response.json();
          setStore({...store, cashbackList: responseJson,loading: false, listLength: responseJson[0].list.length});
        } catch (e) {
          console.log(e);
          setStore({...store, loading: false});
        }
      };

    useEffect(() => {
        getCashback()
    }, [])

    return (
        <StoreContext.Provider value = {[store, setStore]}>
            {props.children}
        </StoreContext.Provider>
    )
}