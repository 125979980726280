import React from 'react'
import AdvantageCol from './AdvantageCol/AdvantageCol';
// css
import './Advantage.css'
// react-bootstrap
import { Container,Row } from 'react-bootstrap';
// photos
import sendMoney from '../../assets/send-money.svg';
import transfer from '../../assets/transfer.svg';
import paint from '../../assets/paint.svg';


const Advantage = () => {
    return (
        <>
        <Container fluid className='advantageContainer'>
            <Container>
                <h2 className='text-center advantageCaption'>Популярно у наших пользователей</h2>
                <Container>
                    <Row className='advantageRow'>
                        <AdvantageCol caption={ 'Переводы' } text= { 'Любимым и родным' } imgSrc={sendMoney}/>
                        <AdvantageCol caption={ 'Обмен валют' } text= { 'C выгодным курсом' } imgSrc={transfer}/>
                        <AdvantageCol caption={ 'Оплата услуг' } text= { 'Удобно и быстро' } imgSrc={paint}/>
                    </Row>
                </Container>
            </Container>
        </Container>
        
        </>
    )
}
export default Advantage;