import React from 'react'
// css
import './Footer.css'
// react-bootstrap
import { Container, Row, Col } from 'react-bootstrap'
// photos
import telegramm from '../../../assets/telegramm.svg';
import facebook from '../../../assets/facebook.svg';
import vk from '../../../assets/vk.svg';
import instagramm from '../../../assets/instagramm.svg';

const Footer = () => {
    return (
        <>
        <Container className='footerContainer'>
            <Row>
                <Col className='col-12 col-md-7 col-lg-7'>
                    <h3 className='footerCaption'>О нас</h3>
                    <p className='footerText'>Прогрессивная и одна из лидирующих финансовых организаций в Таджикистане.</p>
                    <p className='footerAdress'>734025,Ҷумҳурии Тоҷикистон, ш. Душанбе, кӯч. Пушкин 20. Телефон: <a href='tel:+992446002024' className='footerAdress'>+992 44 600-20-24</a></p>
                </Col>
                <Col className='col-12 col-md-5 col-lg-5 d-flex justify-content-start justify-content-md-end justify-content-lg-end'>
                    <div>
                        <h3 className='footerCaptionLast'>Мы в соц-сетях</h3>
                        <div className='pt-3 social-group'>
                            <a href='https://t.me/bonkirushd' target='_blank' className='mr-2' rel = "noopener noreferrer" >
                                <img src={telegramm} alt='Telegram'/>
                            </a>
                            <a href='https://www.facebook.com/bonkirushd' target='_blank' className='mr-2' rel = "noopener noreferrer" >
                                <img src={facebook} alt='Facebook'/>
                            </a>
                            {/* <a href='#' target='_blank' className='mr-2' rel = "noopener noreferrer" >
                                <img src={vk} alt='Vkontakte'/>
                            </a>
                            <a href='#' target='_blank' rel = "noopener noreferrer" >
                                <img src={instagramm} alt='Instagramm'/> 
                            </a> */}
                        </div>
                        <div className='footerTell text-sm-left text-md-right text-lg-right'>
                            <a href='tel:+992446002024' className='footerTell'>+992 44 600-20-24</a>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid className='container-fluid footerLast'>
            <Container>
                <Row>
                    <Col className='col-12 col-md-7 col-lg-7 d-flex align-items-center justify-content-center justify-content-md-start justify-content-lg-start footerLastCopyCol'>
                        <p className='footerLastCopy'>{`© ${new Date().getFullYear()} АО «Банк Развития Таджикистана»`}</p>
                    </Col>
                    <Col className='col-12 col-md-5 col-lg-5 d-flex align-items-center justify-content-center justify-content-md-end justify-content-lg-end footerLastCopyCol'>
                        <p className='footerLastText'>Сделано с любовью, сделано для вас</p>
                    </Col>
                </Row>
            </Container>
        </Container>
        </>
    )
}
export default Footer;