import React from 'react'
// css
import './Product.css'
// react-bootstrap
import { Container } from 'react-bootstrap'
// react-scroll
import { Link} from "react-scroll";
// photos
import phoneCont from '../../assets/productCont.png'


const Product = () => {
    return (
        <Container className='productContainer'>
            <h1 className='productContent text-center '>Твой банк в твоей руке</h1>
            <p className='productCaption'>Простой и безопасный доступ<br id='newLineLast'/> к вашим счетам и картам</p>
            <Link id='productBtn'
             activeClass="active"
             to="download"
             spy={true}
             smooth={true}
             duration={500}
             className='productButton'>
                <button id='productBtn'>Скачать приложение</button>
            </Link>
            <div className='pt-5'>
                <img src={phoneCont} alt='Product_img' className='img-fluid productImg'/>
            </div>
        </Container>
    )
}

export default Product;