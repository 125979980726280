import React from "react";
import Download from "./Download/Download";
// css
import "./DownloadProduct.css";
// react-bootstrap
import { Col, Container, Row } from "react-bootstrap";
// photos
import ios from "../../assets/ios.svg";
import android from "../../assets/android.svg";
import huawei from "../../assets/appgallery.png";
import brtLogo from '../../assets/brtlogo.png'
// react-scroll
import { Element } from "react-scroll";


const DownloadProduct = () => {
  return (
    <>
      <Container fluid className="downloadContainer" style={{ zIndex: "1" }}>
        <Element name="download">
          <Container id="downloadCont">
            <h2 className="downloadText">Скачать приложение БРТ Онлайн</h2>
            <Row className="d-flex justify-content-center align-items-start">
              <Download 
                textBtn={"App Store"} 
                imgSrc={ios} 
                downloadHref={"https://apps.apple.com/app/brt-online/id6463097083"} />
              <Download
                textBtn={"Google Play"}
                imgSrc={android}
                downloadHref={"https://play.google.com/store/apps/details?id=tj.brt.online"}
              />
              <Download 
                  textBtn={"Android - APK"} 
                  downloadHref="https://mobi.brt.tj/brt-online.apk" 
                  imgSrc={brtLogo} 
                /> 
            </Row>
          </Container>
        </Element>
      </Container>
    </>
  );
};

export default DownloadProduct;
