import React from 'react'
import logo from '../../assets/logo.png'
import { Element } from 'react-scroll'
import { Container} from 'react-bootstrap'
import { useHistory } from 'react-router'
const Header = () => {
    const history = useHistory()
    return (
        <Element name='product'>
            <Container>
                <div  className='d-flex justify-content-center' style={{paddingTop:'20px'}}>
                    <div onClick={() => history.push('/')} style={{cursor: 'pointer'}} title='Перейти на сайт'>
                        <img width={154} src={logo} alt='Humo'/>
                    </div>
                </div>
            </Container>
        </Element>
    )
}
export default Header;