import React from 'react'
// css
import './Download.css'
// react-bootstrap
import { Col } from 'react-bootstrap'


const Download = ({textBtn,imgSrc,downloadHref}) => {
    return(
            <Col style={{padding: '0px',zIndex: '1'}} className='downloadCol col-12 col-md-4 col-lg-4 d-flex justify-content-center flex-column align-items-center'>
                <img src={imgSrc} width={300} className='img-fluid downloadImg' alt={`Download ${textBtn}`} />
                <div style={{fontFamily: 'Montserrat', paddingBottom: '20px',marginTop: '10px', fontSize: '16px', fontWeight: 600}}>{textBtn}</div>
                <a href={downloadHref} className='downloadBtn' download>
                    <button id="btnDown">{ `Скачать` }</button>
                </a>
            </Col>
    )
}
export default Download;