import React, { useState } from 'react'
// css
import './Accordion.css'
// react-bootstrap
import { Container } from 'react-bootstrap'
// photos
import arrow from '../../../assets/next.svg'




const Accordion = ({answer,question}) => {
    const [count, setCount] = useState(false)
    
    return (
        <Container id='accordionContainer'>
           <div className={!count ? 'accordionParent' : 'accordionParent_active'} style={{paddingLeft: '0px', paddingRight: '0px'}}>
               <div className='accordionClick d-flex justify-content-between align-items-center' onClick={() => setCount(!count)}>
                    <h4 className='accordionText' style={{margin: 0}}>{question}</h4>
                    <div className='d-flex imgBox justify-content-center align-items-center' style={{width: '24px', height: '24px',flexShrink : '0'}}>
                        <img src={arrow} alt='arrow' className={!count ? 'accordionImg' : 'accordionImg_active'}/>
                    </div>
               </div>
    <div className={!count ? 'accordionLastText' : 'accordionLastText_active'}>{answer}</div>
           </div>
        </Container>
    )
}
export default Accordion;